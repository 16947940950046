import React from 'react';
import colors from '../../App.json';
import { CircleLoader } from 'react-spinners';

const ActivityIndicator = () => {
	return (
		<div style={{
			height: '100%', width: '100%',
			display: 'flex', justifyContent: 'center', alignItems: 'center' 
		}}>
			<CircleLoader
				size={50}
				color={colors.brand}
				loading={true}
			/>
		</div>
	)
}

export default ActivityIndicator
