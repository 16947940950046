import React from 'react';
import './App.css';

import { Provider } from 'react-redux';
import { store, persistor } from './libs/redux/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { PostHogProvider} from 'posthog-js/react';

import Router from './screens/Router'

import { theme } from './libs/styles/Theme'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>

        <PostHogProvider 
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={{
            api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
          }}
        >
        
          <ChakraProvider theme={theme}>
            <BrowserRouter>

              <Router />

            </BrowserRouter>
          </ChakraProvider>

        </PostHogProvider>

      </PersistGate>
    </Provider>
  );
}

export default App;
